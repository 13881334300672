#footer {
  background: #000;
  color: #fff;
  padding: 1em 0;
  font-size: 0.75rem;
  clear: both;
}

#footer hr {
  height: 5px;
  border: none;
  background-color: #b3b3b3;
  margin: 0.5em 0;
  opacity: 1;
}

#footer #footer-copyright {
  font-size: 0.6875rem;
}

#footer .footer-bottom > div {
  margin-top: 25px;
}

#footer .footer-bottom > div ol {
  margin: 0;
  padding: 0;
}

hr {
  height: 5px;
  border: none;
  background-color: #b3b3b3;
}

.clear {
  clear: both;
  float: none !important;
  display: block;
}
