#submenu {
  background-color: black;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -3rem;
  z-index: 20;
}

#submenu ul {
  margin: 0 auto;
  padding: 5px 0 15px 0;
  overflow: hidden;
  display: none;
  max-width: 1060px;
  position: relative;
}

#submenu ul.active {
  display: block;
}

#submenu ul:hover {
  display: block;
}

#submenu li {
  float: right;
  margin-right: 23px;
}

.trustpilot-widget {
  margin-left: -28px;
  margin-top: -5px;
}

.trustpilot-widget {
  margin-left: -16px;
}
