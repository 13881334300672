:root {
  /* font  0.875em = 14px */
  --main-body-font: Arial, serif;
  --header-font-family: 'Open Sans', sans-serif;
  --main-header-font: 800 1.6em 'Open Sans', sans-serif;

  --light-font-weight: 300;
  --normal-font-weight: 400;
  --medium-font-weight: 600;
  --bold-font-weight: 800;

  /* Spaces */
  --small-space: 15px;
}
