@tailwind base;
@tailwind components;
@tailwind utilities;

/*
Make any HTML element clickable by “stretching” a nested link via CSS.
Source: https://getbootstrap.com/docs/4.3/utilities/stretched-link/
*/
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 16px;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  background: #f5f4f4;
  font-family: var(--main-body-font);
}

a {
  font-weight: bold;
}

table {
  border-collapse: collapse;
}

th {
  text-align: left;
}

h3 {
  font-weight: var(--bold-font-weight);
  font-family: var(--header-font-family);
  letter-spacing: -0.05rem;
  color: #000;
  margin-bottom: 5px;
  padding: 0;
}

ol {
  list-style: none;
  padding-inline: 0;
  margin-block: 0;
}

.form-check-input[type='radio'] {
  margin-right: 0.4rem;
}

a.submit,
input.submit,
button.submit {
  background: #09f;
  color: #fff;
  font-size: 1.2rem;
  border: none;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
}

.raleway {
  font-weight: var(--bold-font-weight);
  font-size: 1.125rem;
}

.clearfix {
  display: inline-block;
  width: 100%;
}

.page-inner-width {
  max-width: 1140px;
  margin: 0 auto;
}

.bg-light-grey {
  background: #f5f4f4;
}

.page-content-dark-gray {
  background: #dbdbd9;
}

.max-width {
  max-width: 1060px !important;
}

[data-reach-dialog-overlay] {
  z-index: 100;
  background: hsla(0, 0%, 0%, 0.9) !important;
}

.text-shadow {
  text-shadow: 0 0 0.2em rgb(0 0 0 / 60%);
}

.kids-tees-overlay {
  width: 100px;
  height: 22px;
  background-size: cover;
}
